export const sendContactForm = async (form) => {
    return await fetch('https://n9w2l4wzti.execute-api.us-east-2.amazonaws.com/default/centrixOps', {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        referrerPolicy: "no-referrer",
        body: `
<h1>New Inquiry</h1> <br />
<h2>Client Email: <h2>${form.email} <br />
<h2>Client Name: <h2>${form.name} <br />
<h2>Customer message: </h2>${form.body} <br />
`
    }).then(res => res.data)
}