import * as React from 'react';
import {Avatar, Rating} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {ArrowBack, ArrowForward, FormatQuote} from "@mui/icons-material";

function Testimonial({header, text, name, rating}) {
    return (
        <div className="templatetwo-testimonial" style={{margin: "auto"}}>

            <div className="templatetwo-testemonials-item-content">
                <div className="quites">
                    <FormatQuote fontSize="large" sx={{fontSize: "60px"}} />
                    <Rating name="read-only" value={5} readOnly className="rating"/>
                </div>
                <p>“{text}”</p>
                <p className="username">{name}</p>
            </div>
        </div>
    );
}
const renderStuff = [
    {
        text: "I am excited to give my highest recommendation for Myroslav as an exceptional DevOps engineer. I worked closely with Myro and he significantly improved our infrastructure's security and performance. Myroslav conducted a comprehensive DevOps audit, identifying and fixing vulnerabilities to enhance our security significantly. He quickly optimized our PHP backend configuration, resulting in noticeable performance gains that exceeded expectations, meeting an urgent request to address performance issues. Myroslav also demonstrated strategic planning in implementing robust monitoring and alerting to ensure system's integrity. His expertise with DevOps tools and processes was excellent.\n" +
            "I would be happy to work with Myroslav again on any DevOps initiative.",
        name: "Mario M. Martinez Jr. - CEO at FlyMSG.io"
    },
    {
        text: "Myroslav worked with us for a year. He was always proactive in checking for the system's security.\n" +
            "\n" +
            "He set up and maintained our CI/CD pipelines for publishing mobile apps, as well as the pipelines for backend, frontend, and other microservices, which greatly improved our deployment process.\n" +
            "\n" +
            "He migrated our infrastructure to Kubernetes and applied the latest security best practices while keeping the applications scalable, highly available and cost-effective. He also set up logging, monitoring, and alerting systems.\n" +
            "\n" +
            "Additionally, he implemented GitOps with ArgoCD while keeping every part of the infrastructure documented.\n" +
            "\n" +
            "He worked well with all team members and quickly became an integral part of our team. \n" +
            "\n" +
            "Myroslav significantly contributed to our project and was always committed to improving our setup. I highly recommend him for any future opportunities.",
        name: "Nazar Parashchuk - Project manager at Ociety"
    },
    {
        text: "Myroslav was the lead DevOps Engineer for my startup, Boardroom. Working with him has been an absolutely pleasure. He assisted us with complex and technical tasks like:\n" +
            "\n" +
            " ⁃ Optimized MySQL data structure\n" +
            " - Redis maintenance and troubleshooting\n" +
            " ⁃ Maintained LAMP stack (Linux, Apache, Mysql, PHP)\n" +
            " ⁃ Monitoring and alerting setup\n" +
            "\n" +
            "He is very responsive, communicates well, and delivers high quality output every time. Any software company would benefit immensely from Myroslav's services. Highly recommend!",
        name: "Charles Camisasca"
    },
    {
        text: "In this time he was able to reduce the monthly infrastructure cost from initialy 2.8k€ to 1.4K€. Secured the uptimes and was always behind vulnerability checks to ensure the maximum security for our clients data.",
        name: "Christian, CTIO in Casculate GmbHCTIO"
    },
];
export default function Testimonials() {
    return (
        <div className="templatetwo-testimonials">
            <h1>Customers</h1>

                <Carousel
                    sx={{
                        width: "100%",
                        height: "500px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    NextIcon={<ArrowForward />}
                    PrevIcon={<ArrowBack />}
                    animation="slide"
                    autoPlay={false}
                >
                    {
                        renderStuff.map(item => <Testimonial key={item} {...item} />)
                    }
                </Carousel>
        </div>
    )
}