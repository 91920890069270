import * as React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useNavigate} from "react-router-dom";

export default function SuccessPage() {
    const navigate = useNavigate();

    return (<div className="success-page-template-two">
        <div className="centered">
            <CheckCircleOutlineIcon fontSize="large" />
            <h1>Thank You!</h1>
            <h4>We value your inquiry, Our consultant will contact you soon</h4>
            <button className="templatetwo-navbutton-c" onClick={() => navigate('/')}>Back</button>
        </div>
    </div>)
}