import logo from './logo.svg';
import './App.css';
import Template2 from "./TemplateTwo";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ServicesPage from "./ServicesPage";
import ContactForm from "./ContactForm";
import TemplateTwo from "./TemplateTwo";
import SuccessPage from "./SuccessPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <TemplateTwo />,
    },
    {
        path: "/contact",
        element: <ContactForm />,
    },
    {
        path: "/services",
        element: <ServicesPage />,
    },
    {
        path: "/success",
        element: <SuccessPage />,
    },
    {
        path: "/reviews",
        element: <TemplateTwo />,
    },
]);
function App() {
  return (
    <>
        <RouterProvider router={router} />
    </>
  );
}

export default App;
