import React, {useEffect, useState} from 'react'
import './template2.css'
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import Services from "./components/Services.jsx";
import History from "./components/History";
import Portfolio from "./components/Portfolio";
import CompanyNumbers from "./components/CompanyNumbers";
import Testimonials from "./components/Testimonials";
import Location from "./components/Location";
import WhyUs from "./components/WhyUs";
import Footer from "./components/Footer";
import PortfolioPopup from "./components/PortfolioPopup";
import ContactForm from "./components/ContactForm";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const Template2 = (props) => {
    const location = useLocation();
    const [showPortfolio, setShowPortfolio] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const { color } = useParams();

    const openPortfolio = () => {
        setShowPortfolio(true)
    }
    useEffect(() => {
        document.documentElement.style
            .setProperty('--primary-color', '#' + color);
    }, [color])

    useEffect(() => {
        console.log(location)
        if (location.pathname === '/reviews') {
            var ele = document.getElementById('reviews');
            window.scrollTo(ele.offsetLeft,ele.offsetTop);
        }
        if (location.pathname === '/') {
            var ele = document.getElementById('main');
            window.scrollTo(ele.offsetLeft,ele.offsetTop);
        }
    }, [location])

    return (
        <>
            {
                showPortfolio &&
                <PortfolioPopup
                    closePortfolio={() =>
                        setShowPortfolio(false)}
                />
            }
            <ContactForm open={showContactForm} setOpenClose={(value) => setShowContactForm(value)} />
            <NavBar setOpenClose={(value) => setShowContactForm(value)} />
            <Header setOpenClose={(value) => setShowContactForm(value)} />
            <Services/>
            <WhyUs />

            <Testimonials/>
            <Footer />
        </>
    )
}

export default Template2