import * as React from 'react';
import {Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField} from "@mui/material";
import NavBar from "./components/NavBar";
import bigLogo from './bigLogo.svg'
import {Done} from "@mui/icons-material";
import {useState} from "react";
import {sendContactForm} from "./components/manager";
import {useNavigate} from "react-router-dom";

export default function ContactForm() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const makeInquiry = async () => {
        if (name !== "" && email !== "" && message !== "") {
            setLoading(true)
            const form = {
                email: email,
                name: name,
                body: message,
            }

            await sendContactForm(form);
            setLoading(false)
            navigate('/success');
        }
    }

    return (
        <>
            {
                loading &&
                <div className="loading-screen">
                    <h3>Sending...</h3>
                    <CircularProgress/>
                </div>
            }
            <NavBar/>
            <div className="templatetwo-Contact">
                <div className="sidebyside">
                <div className="why-contact">
                        <div className="why-contact-text">
                            <h2>Contact us</h2>
                            <p>We'll be ready and happy to help you, let's start our partnership together!</p>
                            <div className="service-item">
                                <ul className="black">
                                    <li><Done fontSize="small"
                                              sx={{
                                                  color: "#00d500",
                                              }}/> CI/CD
                                    </li>
                                    <li><Done fontSize="small" sx={{
                                        color: "#00d500",
                                    }}/> Kubernetes
                                    </li>
                                    <li><Done fontSize="small"
                                              sx={{
                                                  color: "#00d500",
                                              }}/> Security
                                    </li>
                                    <li><Done fontSize="small" sx={{
                                        color: "#00d500",
                                    }}/> Application scaling
                                    </li>
                                    <li><Done fontSize="small" sx={{
                                        color: "#00d500",
                                    }}/> Application & infrastructure modernization
                                    </li>
                                    <li><Done fontSize="small"
                                              sx={{
                                                  color: "#00d500",
                                              }}/> Logging
                                        and monitoring
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form">
                        <img src={bigLogo} width={250} alt=""/>
                        <h4>Please provide your contact info</h4>
                        <div className="form-first-last">
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="name"
                                variant="standard"
                                label="Name"
                                type="name"
                                value={name}
                                onChange={e => {setName(e.target.value)}}
                                fullWidth
                            />
                            <div className="business-info">
                                <TextField
                                    autoFocus
                                    required
                                    margin="dense"
                                    id="email"
                                    variant="standard"
                                    name="email"
                                    label="Business Email"
                                    type="email"
                                    value={email}
                                    onChange={e => {setEmail(e.target.value)}}
                                    fullWidth
                                />
                            </div>
                            <div className="message">
                                <TextField
                                    multiline
                                    rows={4}
                                    autoFocus
                                    variant="standard"
                                    margin="dense"
                                    id="comment"
                                    name="help"
                                    value={message}
                                    onChange={e => {setMessage(e.target.value)}}
                                    label="Message"
                                    type="help"
                                    fullWidth
                                />
                            </div>
                            <div className="agreement">
                                <FormGroup>
                                    <FormControlLabel
                                        label="I agnowledged I'll be contacted about my inquiry"
                                        control={<Checkbox
                                        />}
                                    />
                                </FormGroup>
                            </div>
                            <div className="submit-form">
                                <button className="templatetwo-navbutton-c" onClick={makeInquiry}>Submit</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}