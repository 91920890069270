import * as React from 'react';
import {Facebook, Instagram, LinkedIn, X} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export default function Footer() {
    const navigation = useNavigate();

    return (
        <div className="templatetwo-footer flex-center-onerow">
            <div className="templatetwo-footer-flex-two">
                <div className="templatetwo-footer-flex-two-col">
                    <a href="#">About us</a>
                    <a href="" onClick={() => navigation('/services')}>Services</a>
                    <a href="" onClick={() => navigation('/contact')}>Contact Us</a>
                </div>
            </div>
            <div className="templatetwo-footer-flex-three">
                Powered by:
                <a href="https://activnet-tech.com">ActivNet Tech LTD.</a>
            </div>
        </div>
    );
}