import * as React from 'react';
import NavBar from "./components/NavBar";
import devops from './devops.svg';
import launch from './launch.svg';
import troubleshoot from './troubleshoot.svg';
import emergency from './emergency.svg';
import {useNavigate} from "react-router-dom";

export default function ServicesPage() {
    const navigate = useNavigate();

    return (
        <div className="templateTwo-services-page">
            <NavBar />
            <div className="templateTwo-services-page-header"><h1>Our Services</h1></div>
            <div className="flex-column">
                <div className="templateTwo-services-page-content">
                    <div className="services-page-content-item">
                        <div className="content-item-text">
                            <div className="text-flex">
                            <img src={devops} alt="" width={80}/>
                            <h1>DevOps implementation</h1>
                            </div>
                            <ul>
                                <li>Infrastructure as Code (IaC) for consistent, automated deployments</li>
                                <li>Application containerization for portability and scalability</li>
                                <li>Continuous Integration and Deployment (CI/CD) pipelines</li>
                                <li>Cloud migration (AWS, Azure, Google Cloud Platform, etc.)</li>
                                <li>Comprehensive test automation frameworks</li>
                                <li>Automated application 24/7/365 monitoring and insights</li>
                                <li>Application, web server, deployment logging.</li>
                                <li>Performance management.</li>
                                <li>Backup and disaster recovery.</li>
                            </ul>
                            <button className="templatetwo-navbutton-c" onClick={() => navigate('/contact')}>I want this
                            </button>
                        </div>
                    </div>
                    <div className="services-page-content-item">
                        <div className="content-item-text">
                            <div className="text-flex">
                            <img src={launch} alt="" width={80}/>
                            <h1>Startup Launch</h1>
                            </div>
                            <ul>
                                <li>Cloud setup (AWS, Azure, Google Cloud Platform, etc.)</li>
                                <li>Strategic Planning & Roadmap Development</li>
                                <li>Cloud Infrastructure Provisioning & Management</li>
                                <li>CI/CD Pipeline Setup & Automation</li>
                                <li>Site Reliability Engineering (SRE) Practices</li>
                                <li>Continuous Security Integration</li>
                                <li>Monitoring & Observability Solutions</li>
                                <li>DevOps Culture & Mindset Coaching</li>
                            </ul>
                            <button className="templatetwo-navbutton-c" onClick={() => navigate('/contact')}>I want this
                            </button>
                        </div>
                    </div>
                    <div className="services-page-content-item">
                        <div className="content-item-text">
                            <div className="text-flex">
                            <img src={troubleshoot} alt="" width={80}/>
                            <h1>DevOps troubleshoot</h1>
                            </div>
                            <h4>We help to combat various scenarios:</h4>
                            <ul>
                                <li>Application slowness</li>
                                <li>Cloud bill too high (cost reduction/optimization)</li>
                                <li>Database failures</li>
                                <li>CI/CD frequent crashes</li>
                                <li>Competency gaps</li>
                                <li>Delivery misconfigurations</li>
                                <li>Builds/testing pipelines being too long</li>
                                <li>Security vulnerabilities</li>
                            </ul>
                            <button className="templatetwo-navbutton-c" onClick={() => navigate('/contact')}>I want this
                            </button>
                        </div>
                    </div>
                    <div className="services-page-content-item">
                        <div className="content-item-text">
                            <div className="text-flex">
                                <img src={emergency} alt="" width={80}/>
                                <h1>Emergency help</h1>
                            </div>
                            <h4>We offer on-demand 24/7 support for business success confidence with no regular
                                payments</h4>
                            <ul>
                                <li>Server errors</li>
                                <li>Implementation consultation</li>
                                <li>Database failures</li>
                                <li>CI/CD frequent crashes</li>
                                <li>Competency gaps</li>
                            </ul>
                            <button className="templatetwo-navbutton-c" onClick={() => navigate('/contact')}>I want this</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}