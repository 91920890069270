import * as React from 'react';
import {useNavigate} from "react-router-dom";

export default function Header(props) {
    const navigate = useNavigate();

    return (
        <div id="main" className="templatetwo-header">
            <div className="templatetwo-header-textarea">
                <h1 className="templatetwo-header-h2">
                    Slicing Through Deployment Complexity
                </h1>
                <p className="templatetwo-header-p">
                    Empower your startup with strategic, cutting-edge cloud DevOps & FinOps
                </p>
                <button className="templatetwo-header-button" onClick={() => navigate('/contact')}>Get started</button>
            </div>
            <div className="templatetwo-img">
                <div className="itemplatetwo-img-c"></div>
            </div>
        </div>
    );
}