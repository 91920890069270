import * as React from 'react';
import "animate.css/animate.compat.css"
import ScrollAnimation from "react-animate-on-scroll";
import {AttachMoney} from "@mui/icons-material";
import costEff from './cost-eff.svg';
import quality from './quality.svg';
import customer from './customer.svg';
import kpi from './kpi.svg'

export default function WhyUs() {
    return (
        <div className="templatetwo-whyus indents">
            <h6 className="templatetwo-section-title-whyus">Why Choose Us?</h6>
            <h4 className="templatetwo-section-subtext-whyus">We are the best!</h4>
            <div className="templatetwo-whyus-grid">
                <ScrollAnimation animateIn='fadeIn' duration={0.5}>
                    <div className="templatetwo-whyus-grid-item">
                        <img width={80} src={costEff} alt=""/>
                        <h1>Cost Efficiency</h1>
                        <p>We care about your budget, so our prices are the most affordable on the market without compromising on quality. The implementation we provide can be considered the best on the market</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' delay={100} duration={0.5}>
                    <div className="templatetwo-whyus-grid-item">
                        <img width={80} src={quality} alt=""/>
                        <h1>Quality</h1>
                        <p>Quality means security, and we deliver the best scalable AWS architecture that commits to your data security and application performance</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' delay={200} duration={0.5}>
                    <div className="templatetwo-whyus-grid-item">
                        <img width={80} src={customer} alt=""/>
                        <h1>Customer Oriented</h1>
                        <p>We listen to our customers, our main goal is to make you happy and establish a long-lasting partnership</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn' delay={300} duration={0.5}>
                    <div className="templatetwo-whyus-grid-item">
                        <img width={80} src={kpi} alt=""/>
                        <h1>KPI Driven</h1>
                        <p>We target results and delivery rate seriously. Your success is our success, so commitment and consistency is our top priority</p>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}