import * as React from 'react';
import {useState} from "react";
import {TabContext} from "@mui/lab";
import {ArrowForward, Done} from "@mui/icons-material";
import ScrollAnimation from "react-animate-on-scroll";
import {useNavigate} from "react-router-dom";

export default function Services() {
    const [tab, setTab] = useState(0);
    const navigation = useNavigate();

    return (
        <div className="template-two-services indents">
            <h1 className="templatetwo-section-title">Professional Services To Grow With Confidence</h1>
            <div className="template-two-services-list">
                <div className="template-two-services-tabs">

                    <ScrollAnimation animateIn='fadeIn' animateOnce={true} duration={0.5}>
                    <TabContext value={tab}>
                        <div className='tabs'>
                            <div className="tab" style={{borderBottom: tab === 0 ? "2px solid black" : "2px solid rgba(0,0,0, 0.1)"}} onClick={() => setTab(0)}>DevOps DNA</div>
                            <div className="tab" style={{borderBottom: tab === 1 ? "2px solid black" : "2px solid rgba(0,0,0, 0.1)"}} onClick={() => setTab(1)}>Cloud FinOps</div>
                        </div>
                        <div className="side-by-side">
                                <div className={"dev-ops" + (tab === 1 ? " d-none": "")}>
                                    <div className="service-item">
                                        <p>Meet your time-to-market KPIs by eliminating deployment complexities with cutting-edge DevOps practices</p>
                                        <ul>
                                            <li><Done fontSize="small" sx={{
                                                color: "#00d500",
                                                background: "#ededed",
                                                borderRadius: "50%"
                                            }}/> Kubernetes
                                            </li>
                                            <li><Done fontSize="small" sx={{
                                                color: "#00d500",
                                                background: "#ededed",
                                                borderRadius: "50%"
                                            }}/> Security best practices
                                            </li>
                                            <li><Done fontSize="small" sx={{
                                                color: "#00d500",
                                                background: "#ededed",
                                                borderRadius: "50%"
                                            }}/> Application scaling
                                            </li>
                                            <li><Done fontSize="small" sx={{
                                                color: "#00d500",
                                                background: "#ededed",
                                                borderRadius: "50%"
                                            }}/> Application & infrastructure modernization
                                            </li>
                                            <li><Done fontSize="small" sx={{
                                                color: "#00d500",
                                                background: "#ededed",
                                                borderRadius: "50%"
                                            }}/> CI/CD
                                            </li>
                                            <li><Done fontSize="small" sx={{
                                                color: "#00d500",
                                                background: "#ededed",
                                                borderRadius: "50%"
                                            }}/> Logging and monitoring
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="service-image"
                                        style={{backgroundImage: `url("https://images.unsplash.com/photo-1574790398664-0cb03682ed1c?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`}}></div>
                                </div>
                                <div className={"dev-ops" + (tab === 0 ? " d-none": "")}>
                                    <div className="service-image"
                                         style={{backgroundImage: `url("https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`}}></div>

                                    <div className="service-item">
                                        <p>Optimize your cloud cost efficiency and maximize your cloud investment ROI</p>
                                        <ul>
                                            <li><Done fontSize="small" sx={{color: "#00d500", background: "#ededed", borderRadius: "50%"}}/>Cloud cost analysis & optimization</li>
                                            <li><Done fontSize="small" sx={{color: "#00d500", background: "#ededed", borderRadius: "50%"}}/>Autoscaling resources</li>
                                            <li><Done fontSize="small" sx={{color: "#00d500", background: "#ededed", borderRadius: "50%"}}/>Minimizing waste
                                            </li>
                                            <li><Done fontSize="small" sx={{color: "#00d500", background: "#ededed", borderRadius: "50%"}}/>Architecture shift to serverless</li>
                                            <li><Done fontSize="small" sx={{color: "#00d500", background: "#ededed", borderRadius: "50%"}}/>Reserved instances & Saving plans</li>
                                            <li><Done fontSize="small" sx={{color: "#00d500", background: "#ededed", borderRadius: "50%"}}/>Budget monitoring & alerting</li>
                                        </ul>
                                    </div>
                                </div>
                        </div>
                    </TabContext>
                    </ScrollAnimation>
                </div>
            </div>
            <button className="read-more-button" onClick={() => navigation('/services')}>Read More <ArrowForward /></button>
        </div>
    );
}