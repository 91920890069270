import * as React from 'react';
import {AppBar, Toolbar} from "@mui/material";
import bigLogo from '../bigLogo.svg'
import {useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {ArrowBack} from "@mui/icons-material";
import {useState} from "react";

export default function NavBar(props) {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);

    const goToServices = (e) => {
        e.preventDefault();
        navigate('/services')
    }

    const goHome = (e) => {
        e.preventDefault();
        navigate('/')
    }

    return (
        <AppBar position="fixed" sx={{
            padding: "10px",
            background: "white"}}>
            <Toolbar sx={{
                minHeight: "0 !important"
            }}>
                <div className="open-menu" onClick={() => setOpenMenu(true)}><MenuIcon fontSize="medium" sx={{color: "rgb(21, 101, 192)"}}/></div>
                <div className="menu" style={{display: openMenu ? "flex" : "none"}}>
                    <a href="" onClick={() => setOpenMenu(false)}><ArrowBack /></a>
                    <a href="#main" onClick={(e) => goHome(e)}>Home</a>
                    <a href="" onClick={(e) => goToServices(e)}>Our Services</a>
                    <a onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}>Reviews</a>
                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        navigate('/contact');
                    }}>Get Started
                    </a>
                </div>
                <div className="templatetwo-nav">
                    <div className="templatetwo-navlogo">
                        <img src={bigLogo} alt="" width={175} onClick={(e) => goHome(e)}/>
                    </div>
                    <div className="templatetwo-navlinks">
                        <a href="" onClick={(e) => navigate('/')}>Home</a>
                        <a href="" onClick={(e) => goToServices(e)}>Our Services</a>
                        <a href="" onClick={(e) => {
                            e.preventDefault();
                            navigate('/reviews');
                        }}>Reviews</a>
                    </div>
                    <div className="templatetwo-navbutton">
                        <button className="templatetwo-navbutton-c" onClick={() => navigate('/contact')}>Get Started
                        </button>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}